import React, { ReactNode, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import ForWhom from './ForWhom';
import Values from './ValuesPage';
import Mentoring from './Mentoring';
import Measure from './Measure';
import Footer from './Footers/Footer';
import TDInfo from './TD';
import ASInfo from './AS';
import MGInfo from './MG';
import NotFound from './NotFound';
import ContactForm from './ContactForm';
import ContactPage from './ContactPage';
import PrivacyPolicy from './PrivacyPolicy';
import ScheduleMeeting from './ScheduleMeeting';
import References from './References';
import Mastermind from './Mastermind';

type Props = { children?: ReactNode };

export default function Routing({ children }: Props) {
	const [mastermindPageIndex, setMastermindPageIndex] = useState<number>(0); // zero based
	const [insightsClicked, setInsightsClicked] = useState<boolean>(false);

	return (
		<Router>
			<Header />
			{children}
			<Routes>
				<Route path='/' element={<Values />}></Route>
				<Route path='/kontakt/formularz' element={<ContactForm />}></Route>
				<Route path='/kontakt/umow-spotkanie' element={<ScheduleMeeting />}></Route>

				<Route path='/kontakt' element={<ContactPage />}></Route>

				<Route path='/mentoring' element={<Mentoring />}>
					<Route path='tomasz-dalach' element={<TDInfo />}></Route>
					<Route path='aleksandra-slifirska' element={<ASInfo />}></Route>
					<Route path='mieczyslaw-grudzinski' element={<MGInfo />}></Route>
				</Route>

				<Route path='/polityka-prywatnosci' element={<PrivacyPolicy />}></Route>
				<Route
					path='/mastermind'
					element={
						<Mastermind
							currentPage={mastermindPageIndex}
							setCurrentPage={setMastermindPageIndex}
							insightsClicked={insightsClicked}
							setInsightsClicked={setInsightsClicked}
						/>
					}></Route>

				<Route path='/dla-kogo' element={<ForWhom />}></Route>
				<Route path='/na-miare' element={<Measure />}></Route>
				<Route path='/referencje' element={<References />}></Route>

				<Route path='*' element={<NotFound />} />
			</Routes>
			<Footer
				mastermindPage={mastermindPageIndex}
				setMastermindPage={setMastermindPageIndex}
				insightsClicked={insightsClicked}
			/>
		</Router>
	);
}
