import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../images/logo.png';
import { mastermindTitles } from '../../mastermind-texts';
import { BsChevronCompactDown } from 'react-icons/bs';

type Props = {
	mastermindPage: number;
	setMastermindPage: React.Dispatch<React.SetStateAction<number>>;
	insightsClicked: boolean;
};

export default function MastermindFooter({
	mastermindPage,
	setMastermindPage,
	insightsClicked,
}: Props) {
	const location = useLocation();

	const [currentPageText, setCurrentPageText] = useState<string>('wartości');

	useEffect(() => {
		document.querySelectorAll<HTMLLinkElement>('.footer--list>li').forEach((a: HTMLLinkElement) => {
			a.addEventListener('click', () => {
				document.querySelector('.footer--expander')?.classList.remove('active');
				setCurrentPageText(a.textContent || '');
				document.body.style.position = 'relative';
			});
		});
	}, []);

	function handleCurrentPageClick() {
		document.querySelector('.footer--expander')?.classList.toggle('active');
		document.body.style.position = 'fixed';
	}

	function handleCloseFooter() {
		document.querySelector('.footer--expander')?.classList.remove('active');
	}

	return insightsClicked ? (
		<>
			<footer className='w-full text-black site--footer flex justify-center items-center mastermind-footer'>
				<BsChevronCompactDown
					className='absolute left-6'
					size={30}
					onClick={handleCurrentPageClick}
				/>
				<span
					className='uppercase font-semibold text-xs tracking-[2.5px] text-center px-5 max-w-[300px]'
					onClick={handleCurrentPageClick}>
					{mastermindTitles[mastermindPage]}
				</span>
			</footer>
			<div className='footer--expander flex flex-col mastermind-expander'>
				<ul className='flex gap-20 dotted-list in-place items-center tracking-[3px] mt-10'>
					{mastermindTitles.map((title: string, index: number) => {
						return (
							<li
								className={`${index === mastermindPage ? 'active' : ''}`}
								onClick={() => {
									setMastermindPage(index);
									handleCloseFooter();
								}}>
								{title}
							</li>
						);
					})}
					<li>
						<Link to='/'>Strona główna OCEAN CAPITAL</Link>
					</li>
				</ul>
			</div>
		</>
	) : (
		<></>
	);
}
