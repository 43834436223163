import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Grupowe from '../../../images/grupowe1.jpg';
import Logo from '../../../images/logo.png';
import MastermindFooter from './MastermindFooter';
import PageFooter from './PageFooter';

type Props = {
	mastermindPage: number;
	setMastermindPage: React.Dispatch<React.SetStateAction<number>>;
	insightsClicked: boolean;
};

export default function Footer({ mastermindPage, setMastermindPage, insightsClicked }: Props) {
	const location = useLocation();

	return location.pathname === '/mastermind' ? (
		<MastermindFooter
			mastermindPage={mastermindPage}
			setMastermindPage={setMastermindPage}
			insightsClicked={insightsClicked}
		/>
	) : (
		<PageFooter />
	);
}
