import React, { useEffect, useRef, useState } from 'react';
import MastermindIcon from '../../images/icons/mastermind.svg';
import Logo from '../../images/logo_white.png';
import { Link, useLocation } from 'react-router-dom';
import People from '../../images/mastermind_people.png';
import { mastermindTexts, mastermindTitles } from '../mastermind-texts';
import ReactMarkdown from 'react-markdown';

type Props = {
	currentPage: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
	insightsClicked: boolean;
	setInsightsClicked: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Mastermind({
	currentPage,
	setCurrentPage,
	insightsClicked,
	setInsightsClicked,
}: Props) {
	function handleDotClick(e: React.MouseEvent<HTMLDivElement>) {
		const id: number = parseInt(e.currentTarget.id);

		document
			.querySelectorAll<HTMLDivElement>('.mastermind-circles .circle')
			.forEach((circle: HTMLDivElement) => {
				if (circle.id !== 'circle-' + id) {
					circle.classList.remove('active');
				}
			});

		document.getElementById('circle-' + id)?.classList.toggle('active');
	}

	function handleInsightsClick() {
		setInsightsClicked((prev) => !prev);
	}

	useEffect(() => {
		document
			.querySelector<HTMLDivElement>('.site--content')
			?.style.setProperty('--actualViewportHeight', `${window.innerHeight}px`);

		return () => {
			setInsightsClicked(false);
			setCurrentPage(0);
		};
	}, []);

	useEffect(() => {
		document.querySelectorAll('.circle').forEach((circle) => {
			circle.classList.remove('active');
		});
		document.querySelectorAll('.circle-small').forEach((circle) => {
			circle.classList.toggle('hidden', insightsClicked);
		});
		document
			.querySelector('.mastermind-content-displayer')
			?.classList.toggle('active', insightsClicked);
	}, [insightsClicked]);

	useEffect(() => {
		document.querySelector('.mastermind-text-holder')?.scrollTo({
			top: 0,
			left: 0,
		});
	}, [currentPage]);

	return (
		<div className='mastermind-content site--content'>
			<div className='mastermind-icon ' onClick={handleInsightsClick}>
				<div className='relative'>
					<img src={MastermindIcon} className='w-[100px]' />
					<div className='circle-small bottom-[-3px] right-[-5px] opacity-50 forth'></div>
				</div>
			</div>
			{!insightsClicked && (
				<Link to='/' className='cursor-pointer absolute z-[300] bottom-6 left-4'>
					<img src={Logo} className='w-[100px]' />
				</Link>
			)}

			<div className='mastermind-circles'>
				<div className='relative'>
					<div className='circle' id='circle-1'>
						<div>
							<i>
								„Umysł ludzki można porównać do baterii elektrycznej. Ogólnie wiadomo, że zespół
								baterii elektrycznych wytwarza więcej energii od baterii pojedynczej.
							</i>
							<span className='font-bold opacity-50'>Napoleon Hill</span>
						</div>
					</div>
					<div className='circle-small first' id='1' onClick={handleDotClick}></div>
				</div>
				<div className='relative'>
					<div className='circle' id='circle-2'>
						<div>
							<span className='font-bold mt-6'> Tomasz Dalach</span>
							<span className=''>Mentor prowadzący</span>
							<span className='opacity-60'>MASTERMIND OCEAN CAPITAL </span>
							<Link to='/mentoring/tomasz-dalach' className='w-fit ml-auto'>
								<i className='opacity-60 font-bold'>Link</i>
							</Link>
							<span className='font-bold mt-6'> Aleksandra Ślifirska</span>
							<span className=''>Mentorka wspierająca</span>
							<span className='opacity-60'>MASTERMIND OCEAN CAPITAL </span>
							<Link to='/mentoring/aleksandra-slifirska' className='w-fit ml-auto'>
								<i className='opacity-60 font-bold'>Link</i>
							</Link>
						</div>
						<img src={People} />
					</div>
					<div className='circle-small second' id='2' onClick={handleDotClick}></div>
				</div>
				<div className='relative'>
					<div className='circle' id='circle-3'>
						<div>
							<i>
								„Żaden umysł nie jest kompletny sam w sobie. Każdy potrzebuje połączenia i kontaktu
								z innymi umysłami, aby wzrastać i rozszerzać swoją świadomość.”
							</i>
							<span className='font-bold opacity-50'>Napoleon Hill</span>
						</div>
					</div>
					<div className='circle-small third' id='3' onClick={handleDotClick}></div>
				</div>
				<div className='relative'>
					<div className='circle' id='circle-4'></div>
				</div>
			</div>
			<div className='mastermind-content-displayer text-white flex justify-between'>
				<div className='flex flex-col gap-10 max-h-[60vh] overflow-hidden leading-[34px] mastermind-text-holder'>
					<ReactMarkdown
						components={{
							em: (props) => {
								return <em className='tracking-[2px]'>{props.children}</em>;
							},
							h1: (props) => {
								return (
									<h1 className='tracking-[2px] text-2xl font-extralight'>{props.children}</h1>
								);
							},
							p: (props) => {
								return <p className='tracking-[1px] font-extralight'>{props.children}</p>;
							},
							a: (props) => {
								return (
									<a href={props.href} target='_blank' className='font-medium'>
										{props.children}
									</a>
								);
							},
						}}>
						{mastermindTexts[currentPage]}
					</ReactMarkdown>
				</div>
			</div>
		</div>
	);
}
